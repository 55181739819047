import moment from 'moment'; //eslint-disable-line no-unused-vars
import 'moment/locale/fi';
import 'moment/locale/ru';
import 'moment/locale/uk';
import 'moment/locale/ar';
import 'moment/locale/ku';

const translations = {
  fi: {
    // feedback
    failed_to_find_prebooking_data: 'Tätä ennakkovarausta ei löytynyt',
    prebooking_was_cancelled: 'Tämä ennakkovaraus peruttiin',
    feedback_not_open_yet: 'Koska tulkkaus ei ole vielä tapahtunut, palautteen anto ei ole vielä mahdollista.',
    how_did_we_do: 'Miten tulkkaus meni?',
    stars_rating_subtitle: 'Palautteesi auttaa meitä parantamaan palvelua.',
    where_can_we_improve_upon: 'Missä voisimme parantaa?',
    leave_open_feedback: 'Jätä avointa palautetta', 
    describe_your_experience_here: 'Kerro omin sanoin kokemuksestasi',
    i_wish_to_be_contacted: 'Ottakaa minuun yhteyttä',
    choose_one_or_more_options: 'Valitse yksi tai useampi vaihtoehto',
    thank_you_for_your_feedback: 'Kiitos palautteesta!',
    // customer guide for od-call (modal)
    order_an_interpreter_by_calling_the_number: 'Tilaa tulkki soittamalla numeroon',
    main_calling_number: '041 400 1234',
    main_calling_number_with_code: '*31# 041 400 1234',
    main_calling_number_with_zero: '0041 400 1234',
    phone_must_be_registered: 'Puhelin, jolla soitat akuuttinumeroomme tulee olla rekisteröity järjestelmäämme.',
    note: 'Huom!',
    number_is_encrypted: ' Jos numerosi on salattu niin kirjoita palvelunumero seuraavassa muodossa:',
    attention_for_hus: 'Huomio HUSin lankaverkossa toimijat!',
    if_neither_number_works: ' Jos kumpikaan numero ei toimi, niin kirjoita palvelunumero seuraavassa muodossa:',
    contact_to_customer_service: 'Ongelmatapauksessa ota yhteyttä asiakaspalveluumme.',
    select_interpreter_language: 'Puhelun yhdistettyä valitse tulkattava kieli seuraavista syöttämällä numero puhelimen numeronäppäimillä',
    accept_incoming_prebookings: 'Hyväksy tai hylkää saapuneet ennalta varatut tulkkaukset',
    accept: 'Hyväksy',
    decline: 'Hylkää',
    accept_all: 'Hyväksy kaikki',
    date_and_time: 'Ajankohta',
    duration: 'Kesto',
    language: 'Kieli',
    presence: 'Tyyppi',
    prebookings_already_checked: 'Varaus on jo valittu',
    username_not_unique: 'Käyttäjätunnus ei ole yksilöllinen.',
    new_username_set: 'Uusi käyttäjätunnus asetettu: ',
    // LUONA signup
    luonaSignupForm: 'Kirjautumislomake',

    label_name: 'Etu- ja sukunimi',
    placeholder_name: 'Nimi latinalaisin kirjaimin',
    validate_name: 'Vähintään neljä kirjainta',

    label_phone: 'Puhelinnumero',
    placeholder_phone: '+3XXXXXXXXXXX',
    validate_phone: 'Puhelinnumero muodossa: +XXXXXXXXXXXX',

    label_email: 'Sähköpostiosoite',
    placeholder_email: 'user@mail.com',
    validate_email: 'Sähköpostiosoite muodossa: user@mail.com',

    label_city: 'Vain numeroita',
    placeholder_city: 'Kaupunki',
    validate_city: 'Kaupunki',
    selectOption: 'Vain latinalaisia kirjaimia',

    agreePrivacyPolicy: 'Hyväksyn ',
    privacyPolicy: 'tietosuojakäytännön',
    luonaSignupButton: 'Lähetä',
    requestErrorMessage: 'Jotain meni pieleen, yritä uudestaan!',
    minimalPrebookingTimeBumperError: 'Tämä aika on liian lähellä nykyistä aikaa. Valitse toinen aika, kiitos.',
    new_password_success: 'Salasana vaihdettu',
    new_password_wrong: 'Väärä vanha salasana',
    requestErrorMessageUserNotFound: 'Anna rekisteröity puhelinnumero ja sähköpostiosoite.',
    requestErrorMessageUserExist:
      'Antamasi sähköpostiosoite tai puhelinnumero on jo käytössä, ole hyvä ja käytä toista.',
    slot_not_free_anymore: 'Valittu aikaväli ei valitettavasti ole enää käytettävissä',
    no_interpreter_in_prebooking: 'Varauksessa ei ole tulkkia.',
    back_to_telegram: 'Palaa Telegramiin',
    // LUONA
    loginTitle: 'Sisäänkirjautuminen',
    username: 'Käyttäjätunnus',
    password: 'Salasana',
    loginButton: 'Kirjaudu',
    loginFailed: 'Sisäänkirjautuminen epäonnistui.',
    sessionExpired: 'Istunto on vanhentunut. Ole hyvä ja kirjaudu uudelleen.',
    meeting_closed: 'Tapaaminen on päättynyt.',
    signin_code_block_message: 'Tämä sähköpostiosoite on jo käytössä. Lähetimme vahvistuskoodin osoitteeseen: [email]. Kirjoita vahvistuskoodi allaolevaan kenttään.',
    placeholder_signin_code: 'koodi',
    close_meeting_confirmation_message: 'Oletko varma että haluat poistua tapaamisesta? Tapaaminen päättyy tällöin kaikilta osapuolilta',
    internet_connection_lost: 'Verkkoyhteys katkesi',
    internet_connection_exist: 'Verkkoyhteys palautui',
    what_is_your_language: 'Hei, mitä kieltä ymmärrät?',
    what_you_need_help_with: 'Mihin tarvitset apua?',
    selectable_langs: 'Kieli',
    selectable_servises: 'Asiani koskee',
    selectable_time: 'Aika',
    selectable_interpreter: 'Tapaamisen kieli on englanti tai suomi. Tarvitseko tulkin?',
    authtorize: 'Kirjautumislomake',
    choose_time: 'Valitse varattava aika',
    prebook_not_available_message: 'Palvelu ei ole saatavilla tällä hetkellä. Palveluajat ovat ma-pe {SCHEDULE}.',
    //Luona services
    health: 'Terveyttä',
    work_or_study: 'Työtä tai opiskelua',
    money_or_livelihood: 'Rahaa tai toimeentuloa',
    reception_allowance: 'Vastaanottoraha tai PFS-korttia',
    my_money_is_not_enough: 'Rahani eivät riitä',
    copy_email: 'Kopioi sähköpostiosoite',
    successfully_copied: 'Sähköpostiosoite kopioitu',
    other_practical_matters: 'Muut käytännön asiat',
    thanks_for_booking: 'Kiitos varauksestasi.',
    prebook_created_at: 'Videotapaamisesi on vahvistettu seuraavasti:',
    prebooking_skill: 'Aihe:',
    you_need_high_network: 'Tapaamisen onnistumisen ja laadun varmistamiseksi huolehdithan, että sinulla on vakaa ja nopea verkkoyhteys.',
    you_will_got_accept: 'Saat meiltä varausvahvistuksen sekä kaksi muistutusta tekstiviestitse ja sähköpostitse.',
    message_with_link_to_meeting: 'Viestit sisältävät linkin, joista pääset tapaamiseen sovittuna ajankohtana.',
    dont_forget_decline_prebooking: 'Muistathan perua tapaamisen, mikäli ajankohta ei sovi sinulle.',
    for_decline_need: 'Voit peruuttaa varauksesi osiossa:',
    click_for_decline_prebooking: 'ja napsauta "Poista" -painiketta.', 
    best_regards: 'Ystävällisin terveisin,',
    luona_DigiVOK: 'Luona DigiVOK',
    emigration_or_holiday_outside_Finland: 'Maastamuuttoa tai lomaa Suomen ulkopuolella',
    free_time: 'Vapaa-aika',
    housing: 'Asumista',
    child_care: 'Lasta',
    child_health: 'Terveys',
    upbringing_parenting: 'Kasvatus/vanhemmuus',
    kindergarten_or_school: 'Päiväkoti tai koulu',
    reception_centre_info_service: 'Vastaanottokeskuksen neuvonta',
    physical_or_mental_symptoms: 'Fyysiset tai psyykkiset oireet',
    related_to_money_or_livelihood: 'Rahaan tai toimeentuloon liittyvää',
    related_to_your_own_or_family_life_situation:
      'Omaan tai perheen elämäntilanteeseen liittyvää',
    legal_matter: 'Oikeudellista asiaa',
    everyday_practical_things: 'Päivittäiset, arkiset käytännön asiat',
    service_housing: 'Majoitukseen liiittyvät käytännön asiat',
    how_do_you_judge_the_criticality_of_your_case:
      'Kuinka itse arvioi asiaasi kriittisyyden?',
    help_need_today: 'Asiani olisi hyvä saada kuntoon tänään',
    help_need_in_couple_of_days:
      'Asiani olisi hyvä saada kuntoon lähipäivinä',
    help_need_in_week: 'Asiani on saatava hoidettua viikon sisällä',
    help_is_not_urgent: 'Asiani ei ole kiireellinen',
    call_now: 'Soittaa heti',
    book_an_appointment: 'Varata ajan',
    authtorize_yourself: 'Tunnistaudu eli kerro meille kuka olet',
    user_successfully_registered: 'Rekisteröityminen onnistui',
    book_time: 'Varaa aika',
    what_time_works_for_you: 'Mikä aika sinulle sopii?',
    wizard_default_message:
      'Mikäli tilanne on henkeä uhkaava, soita 112.\nMikäli terveysasiasi ei voi odottaa seuraavaan arkipäivään, soita päivystysapu 116117. \nMikäli sinulla on muu akuutti kriisi, soita asuinkuntasi sosiaali- ja kriisipäivystykseen.',
    wizard_welcome_message:
      'Tervetuloa! Teemme yhdessä vaikuttavia sote-palveluja. Voimmeko olla avuksi?',
    wizard_PrebookingStep_header:
      'Pyrimme hoitamaan tilanteet lähtökohtaisesti heti. Mikäli kaikki työntekijämme ovat varattuina, sinulla on mahdollisuus varata aika tätä kautta. Haluaisitko varata ajan vai soittaa heti?',
    wizard_TimeStep_header:
      'Valitettavasti kukaan ei päässyt nyt vastaamaan. Pääset varaamaanajan tästä:',

    errorTitle: 'Hupsista!',
    warnTitle: 'Hmmm...',
    successTitle: 'Mahtavaa!',

    selectLanguage: 'Valitse kieli',
    interpreter: 'Tulkki',
    startInterpreting: 'Aloita tulkkaus',
    requestInterpreting: 'Pyydä tulkkausta',

    interpretationNeeded: 'Tulkkauspyyntö',
    requestForInterpretation: 'Tulkkausta tarvitaan!',
    requestForCall: 'Asiakas tarvitsee apua',
    nothingToInterpret: 'Ei tulkattavaa',
    noInterpretationNeeded: 'Kaikkiin tulkkauspyyntöihin on vastattu.',

    doYouWantToHangUp: 'Haluatko päättää tulkkauksen?',
    oppositeSideHangsUp: 'Vastapuoli katkaisi yhteyden.',
    networkTerminated: 'Yhteys katkesi.',
    review: 'Arvostele tulkkaus',
    customerFeedbackEnjoyingTulka: 'Sujuiko tulkkaus?',
    customerRatingInterpreter: 'Arviosi tulkkauksen laadusta',
    customerInvoiceNotes: 'Laskutusviite',
    interpreterRatingInterpreter: 'Sujuiko tulkkaus?',
    interpreterRatingCustomer: 'Kuinka suoriuduin tulkkauksessa',
    ratingQuality: 'Arviosi yhteyden laadusta',
    feedback: 'Palaute',
    feedbackDesc: 'kirjoita kommenttisi tähän',
    feedbackContactMe: 'Ottakaa minuun yhteyttä',
    proceed: 'Lähetä',
    skip: 'Ohita',

    endCall: 'Lopeta puhelu',
    refresh: 'Päivitä',
    logout: 'Kirjaudu ulos',
    toggleCamera: 'Hallitse kameraa',
    toggleAudio: 'Mykistys',

    onlyChromeSupported: 'Selaimesi ei ole tuettu',
    pleaseUseChrome: 'Asenna Google Chrome -selain käyttääksesi Túlkaa',
    downloadChrome: 'Lataa Google Chrome',
    continueInterpretation: 'Jatka tulkkausta',
    roomInfoCallEndedClosed: 'Palvelin sulki keskustelun.',
    feedbackTooLate: 'Tulkkauksen jatkaminen on mahdollista minuutin ajan!',

    noTimeLeft: 'Esittelyaika on loppunut. Olemme pian yhteydessä sinuun!',
    minutesConsumed:
      'Esittelyaika on nyt päättynyt. Olemme pian yhteydessä sinuun!',
    timeLeft: 'Esittelyaikaa jäljellä: {}',
    zeroTimeLeft: 'Esittelyaika on loppunut.\nOlemme pian yhteydessä sinuun!',
    freeUseLeft: 'Aikaa jäljellä',

    failedToSendFeedback: 'Palautteen lähettäminen epäonnistui!',
    failedLogOut: 'Uloskirjautuminen ei onnistunut.',
    failedToConnect: 'Ei yhteyttä palvelimeen.',
    waitingForInterpreter:
      'Pyyntösi tavoittaa tulkkeja parhaillaan. Joku heistä vapautuu pian tulkkaamaan. Odotus kestää yleensä 1-3 minuuttia.',
    waitingForCustomer: 'Odotetaan asiakasta...',
    pleaseWait: 'Hetkinen...',
    youHaveWaitedFor: 'Olet odottanut {}',
    error500: 'Jotain meni vikaan. Yritäthän uudelleen.',
    roomInfoHasAudioPacketLoss: 'Yhteydessä ongelmia!',

    yes: 'Kyllä',
    no: 'En',
    cancel: 'Peruuta',

    default: 'Oletusarvoinen',
    termsOfUse: 'Käyttöehdot ja tietosuoja',
    options: 'Näytä asetukset',
    hideOptions: 'Piilota asetukset',

    enable_notifications: 'Kytke ilmoitukset',
    disable_notifications: 'Sammuta ilmoitukset',
    disable_duration: 'Sammuta ilmoitukset',
    for_hour: 'tunniksi',
    for_day: 'päiväksi',
    for_week: 'viikoksi',
    for_month: 'kuukaudeksi',
    for_a_day: '1 päivä',
    for_days: '{} päivää',
    for_a_day_an_hour: '1 päivä, 1 tunti',
    for_a_day_hours: '1 päivä, {} tuntia',
    for_days_an_hour: '{} päivää, 1 tunti',
    for_days_hours: '{d} päivää, {h} tuntia',
    notifications_disabled_for: 'Ilmoitukset pois päältä\n{}',
    failed_to_disable_notifications:
      'Ilmoitusten sammutus ei onnistunut. Yritä myöhemmin uudelleen.',
    failed_to_enable_notifications:
      'Ilmoitusten kytkentä ei onnistunut. Yritä myöhemmin uudelleen.',
    forgot_password_link: 'Unohditko salasanan?',
    forgot_password_info:
      'Lähetämme sinulle uuden salasanan tekstiviestinä tai sähköpostina. Jos sinulla ei ole tunnuksia, ota yhteyttä info@tulka.com.',
    placeholder_phone_number: '+35840...',
    phone_number_title: 'Puhelinnumero',
    forgot_password_error:
      'Tekstiviestiä tai sähköpostia ei voitu lähettää. Ota yhteyttä osoitteeseen info@tulka.com',
    forgot_password_success:
      'Saat uuden salasanan kohta tekstiviestinä tai sähköpostiin.',
    skillModalQuestion:
      'Tarvitsetko alla olevaa erityisosaamista? Odotusaika voi olla pidempi.',
    targetLanguage: 'Kieli jota ymmärrät',
    openingTimes: '',
    systemAlert: 'Palvelussa teknisiä häiriöitä. Pahoittelemme.',

    chatFailed: 'Tekstichattiin kytkeytyminen epäonnistui.',
    chatTipInterpreter:
      'Täällä voit vaihtaa viestejä asiakkaan kanssa. Viestit tuhotaan välittömästi, kun tulkkaus päättyy.',
    chatTipCustomer:
      'Täällä voit vaihtaa viestejä tulkin kanssa. Viestit tuhotaan välittömästi, kun tulkkaus päättyy.',
    chatSendFailed: 'Viestin lähetys epäonnistui.',
    chatTypeMessage: 'Kirjoita viesti...',
    invoiceRefMandatory: 'Syötä vielä laskutusviite.',
    invoiceRefUserGroupMandatory: 'Valitse vielä laskun saaja.',
    selectInvoiceRefUserGroup: '-- Valitse --',

    show_feedbacks: 'Tähtiarviot',
    show_feedbacks_title: 'Näin minua arvioitiin',
    failedToFetchFeedbacks: 'Failed to fetch the ratings.',
    failedToCreateChatroom: 'Tähtiarvioiden hakeminen epäonnistui',
    statistics: 'Omat tulkkausmäärät',
    no_statistics: 'Tulkkausmääriä ei ole saatavilla.',
    stats_disclaimer:
      'Tulkkausmäärät ovat vain arvioita, eivätkä välttämättä täysin vastaa lopullisen palkkalaskelman määriä.',
    stats_today: 'Tänään',
    stats_yesterday: 'Eilen',
    stats_this_week: 'Tällä viikolla',
    stats_last_week: 'Viime viikolla',
    stats_this_month: 'Tässä kuussa',
    stats_last_month: 'Viime kuussa',
    stats_this_year: 'Tänä vuonna',
    stats_last_year: 'Viime vuonna',

    feedbackButton: 'Paina kirjoittaaksesi palautetta',
    feedback_already_exist: 'Palaute annettu',
    feedback_need_to_leave: 'Palaute antamatta',
    feedback_do_next: 'Voit nyt sulkea tämän ikkunan.',
    chatroom_not_exist: 'Palautteen anto ei vielä mahdollista',

    // Prebooking
    prebook: 'Tee ennakkovaraus',
    prebooking_date_header: 'Milloin tarvitset tulkin?',
    prebooking_date_header_notice: 'Ilmoituksen viimeinen toimituspäivä',
    step: '{step} / {steps}',
    choose_button: 'Valitse',
    prebooking_duration_header: 'Kuinka kauan arvioit tarvitsevasi tulkkia?',
    hours_minutes: '{h}:{m}',
    hour_minutes: '{h}:{m}',
    minutes: '{} min',
    hour: '60 min',
    hours: '{}:00',
    customer_reference: 'Tiedot tulkkauksesta laskulle',
    customer_reference_placeholder: 'Kentän tiedot eivät tule näkyviin tulkille',
    please_provide_customer_reference:
      'Annatko vielä lisätiedot tulkkauksesta laskulle.',
    prebooking_time_header: 'Mihin kellonaikaan tarvitset tulkin?',
    select_language_title: 'Minkä kielisen tulkin tarvitset?',
    fetching_languages: 'Haetaan kieliä...',
    invoice_cause: 'Laskun kohdistaminen',
    invoice_cause_description: 'Valitse maksaja / kustannuspaikka',
    invoice_ref_placeholder: 'HUOM! Asiakaspalvelu ei lue tätä.',
    call_type_video: 'Video',
    call_type_call: 'Puhelu',
    call_type_question: 'Mitä yhteyttä haluat käyttää?',
    additional_order_info: 'Tilauksen lisätiedot',
    confirm_prebooking: 'Tarkista varauksen tiedot',
    comment: 'Välttämätön lisätieto käsittelijälle',
    prebooking_date: 'Päivä',
    prebooking_time: 'Kellonaika',
    prebooking_duration: 'Kesto',
    language_title: 'Kieli',
    presence_title: 'Tulkkauksen tyyppi',
    call_type_title: 'Yhteystyyppi',
    select_target_language_title: 'Mitä kieltä ymmärrät?',
    location_question: 'Missä tulkkaus tapahtuu?',
    location_placeholder_city: 'Kaupunki',
    location_placeholder_address: 'Lähiosoite',
    location_placeholder_postcode: 'Postinumero',
    add_message_to_interpreter: 'Lisää viesti tulkille',
    subject: 'Tulkkauksen aihe',
    interpretation_location_details: 'Saapumisohjeet tulkkauspaikalle (esim. toimipiste, rappu, kerros, huone jne.)',
    address_info: 'Osoitetiedot',
    arrival_instructions: 'Saapumisohjeet',
    addressAndArrivalInstrustions: 'Osoite ja saapumisohjeet',
    preferred_interpreters_question: 'Haluatko valita mieluisan tulkin?',
    search_preferred_interpreter: 'Hae tulkki nimellä',
    not_guarantee: 'Yritämme saada toivotun tulkin, mutta emme pysty takaamaan hänen olevan vapaana.',
    history_is_limited: 'Haun tulos on rajallinen, koska et ole käyttänyt vielä montaakaan tulkkia.',
    presence_question: 'Minkä tyyppinen tulkkaus on kyseessä?',
    presence_remote: 'Etätulkkaus',
    presence_remote_tip: 'Puhelun tai videon välityksellä tapahtuva etätulkkaus',
    presence_on_site: 'Lähitulkkaus',
    presence_on_site_tip: 'Tulkki paikan päällä',
    presence_notice: 'Ilmoitustulkkaus',
    presence_notice_tip:
      'Tekstimuotoinen viesti toimitetaan puhuttuna tulkkauksena',
    select_favorite: 'Haluatko valita mieluisimmat tulkit?',
    any_favorite: 'Kuka tahansa käy',
    call_type_call_tip: 'Vain ääni normaalina puheluna',
    call_type_video_tip: 'Mobiilisovelluksen kautta avattava videoyhteys',
    target_language_title: 'Kieli jolla työskentelet',
    invoice_ref_title: 'Tiedot tulkkauksesta laskulle',
    save_prebooking: 'Lähetä varaus',
    edit_prebooking: 'Tallenna',
    any_gender: 'Ei väliä',
    any_dialect: 'Mikä tahansa käy',
    gender_title: 'Sukupuoli',
    dialect_title: 'Murre',
    select_skill: 'Onko sinulla erityistarpeita tulkille?',
    select_dialect: 'Tarvitsetko jonkin erityisen murteen?',
    select_gender: 'Onko tulkin sukupuolella väliä?',
    any_skill: 'Ei erityistarpeita',
    skill_title: 'Erityistarpeet',
    unconfirmed_prebooking: 'Odottaa vahvistusta',
    confirmed: 'Vahvistettu',
    delivered: 'Toimitettu',
    not_able_to_reach: 'Ei tavoitettu',
    cancelled: 'Peruutettu',
    active: 'Aktiivinen',
    location_title_city: 'Kaupunki',
    location_title_address: 'Lähiosoite',
    searching_interpreter: 'Etsitään tulkkia',
    please_call_to_this_number: 'Soita tähän numeroon',
    // Help tooltips
    help_prebooking_summary_title: 'Kirjoita tähän ainoastaan välttämätön lisätieto käsittelijälle',
    help_prebooking_summary:
      'Huom! Lisätietokenttään kirjoittaminen pidentää tilauksen käsittelyaikaa.',
    help_call_type_title: 'Tulkkausyhteys',
    help_call_type:
      'Puhelutulkkauksella voit liittää yhteen useamman eri paikassa olevan ihmisen (konferenssipuhelu).\n\nVideotulkkauksessa ihmiset näkevät toisensa ja voit esimerkiksi pyytää tulkkia kääntämään tekstiä. Videotoiminnon voi kytkeä päälle ja pois tulkkauksen aikana.\n\nVoit halutessasi vaihtaa tulkkausmuodon kesken kaiken päättämällä tulkkauksen ja valitsemalla \'Jatka tulkkausta\'.',
    help_gender_title: 'Tulkin sukupuoli',
    help_gender:
      'Voit halutessasi valita tulkin sukupuolen. Huomioithan, että valinta vähentää käytettävissä olevien tulkkien määrää ja voi vaikeuttaa tulkin saatavuutta.',
    help_dialect_title: 'Tulkin osaama murre',
    help_dialect:
      'Voit halutessasi valita tulkin osaaman murteen. Huomioithan, että valinta vähentää käytettävissä olevien tulkkien määrää ja voi vaikeuttaa tulkin saatavuutta.',
    help_confirmation_title: 'Vahvista kotikustannuspaikka',
    help_confirmation:
      'Tekemäsi tilaukset kirjataan oletuksena kotikustannuspaikkaasi, ellet valitse tilaukselle poikkeavaa kustannuspaikkaa.',
    tomorrow: 'Huomenna',
    today: 'Tänään',
    now: 'Nyt',
    help_location_title: 'Sijainti/toimipaikka',
    help_location:
      'Kirjoita paikka, jossa tulkkaus tapahtuu. Esimerkiksi toimipaikka tai osoite.',
    help_favorites_title: 'Mieluisimmat tulkit',
    help_favorites:
      'Voit valita yhden tai useamman suosikkitulkkisi.\n\nHuomaa, että toivotut tulkit eivät välttämättä ole käytettävissä valittuna ajankohtana.',
    on_site_not_available_in_app:
      'Läsnäolotulkkausta ei voi aloittaa sovelluksesta.',
    back: 'Takaisin',
    rejoin_meeting: 'Liity uudestaan tapaamiseen',
    change_language: 'Vaihda kieli',
    prebook_successfully_created: 'Varauksen lisääminen onnistui',
    prebook_successfully_edit: 'Ennaltavaraus päivitetty',
    prebook_successfully_cancelled: 'Varauksen peruuttaminen onnistui',
    prebook_successfully_accepted: 'Varaus hyväksytty',
    prebooking_datails: 'Varauksen tiedot',
    prebook_confirmation_message: 'Oletko varma että haluat perua varauksen?',
    cancel_prebook_confirmation_message:
      'Oletko varma että haluat perua varauksen?',
    really_cancel_prebooking_too_late:
      'Oletko varma että haluat perua varauksen? Ilmainen peruminen ei ole enää mahdollista.',
    cancel_reason_selection: 'Mistä syystä haluat peruuttaa?',
    cancel_reason_sickness: 'Oma sairastuminen',
    cancel_sickness_tip: 'Lääkärintodistus vaaditaan',
    cancel_reason_mistake: 'Vahinko',
    cancel_reason_other: 'Muu syy',
    cancel_reason_other_tip: 'Vapaamuotoinen selitys vaaditaan',
    cancel_other_title: 'Selitys',
    cancel_other: 'Kerro mitä tapahtui?',
    cancel_sickness_title: 'Lääkärintodistus',
    cancel_sickness: 'Ole hyvä ja toimita lääkärintodistus sairastumisesta.',
    copy_information: 'Kopioi tiedot',
    my_bookings: 'Varaukset',
    interpreter_now: 'Tulkki heti',
    pre_book: 'Varaa tulkki',
    time: 'Aika',
    status: 'Tila',
    search: 'Haku',
    favorite_title: 'Mieluisimmat tulkit',
    no_prebookings: 'Sinulla ei ole varauksia.',
    prebooking_not_active: 'Sopimuksesi ei sisällä ennaltavaraamista.',
    reservation_number: 'Varausnumero',
    email: 'Sähköpostiosoite',
    not_yet_support_interpreter_login:
      'Uusi selainversio ei vielä sisällä tulkin toimintoja. Ole hyvä ja käytä mobiiliappia.',
    please_try_again_later: 'Yritä myöhemmin uudelleen.',
    no_languages: 'Ei kieliä',
    interpreter_phone_number: 'Tulkin puhelinnumero',
    remove: 'Poistaa',
    presence_conference: 'Neuvottelu / virtuaalihuone',
    presence_conference_tip: 'esim. Microsoft Teams, Skype, Google Meet, Zoom',
    interpreter_email: 'Tulkin sähköposti',
    all: 'Kaikki',
    invoice_user_group: 'Kustannuspaikka',
    cc_emails: 'Tilausvahvistuksen ja muutosilmoitusten vastaanottajat',
    cc_email: 'Sähköpostiosoite',
    cc_emails_copy: 'Tilausvahvistuksen ja muutosilmoitusten vastaanottajat',
    patient_name: 'Tulkattavan nimi',
    patient_name_description: 'Tämä tieto ei tule laskulle vaan menee tulkille tiedoksi esim. jääviystilanteita varten.',
    customer: 'Asiakas',
    customer_unit: 'Yksikkö',
    personal: 'Omat',
    search_for: 'Hae yksiköllä, kielellä, kustannuspaikalla tai asiakkaalla',
    failed_to_create_prebooking:
      'Varauksen luominen epäonnistui. Yritä myöhemmin uudelleen.',
    to_start_interpretation: 'Aloita tulkkaus soittamalla numeroon:',
    start_interpretation: 'Aloita tulkkaus',
    hey_username: 'Hei {username},',
    confirm_your_account_information: 'Vahvista käyttäjätilisi perustiedot',
    confirm_your_account_information_description:
      'Mahdollisimman sujuvan palvelun toimittamiseksi pyydämme sinua vahvistamaan tai korjaamaan käyttäjätunnukseesi liittyvät alla olevat tiedot',
    other: 'Muu',
    no_options: 'Ei hakutuloksia',
    search_message: 'Hae...',
    i_confirm_the_information: 'VAHVISTAN TIETONI OIKEIKSI',
    billing: 'Laskutus',
    checking_your_billing_information: 'Laskutustietojen tarkistus',
    check_that_the_interpretation_is_for_the_correct_cost_center:
      'Tarkista että tulkkaus kohdistuu oikeaan kustannuspaikkaan/yksikköön ja että laskutusviitetiedot ovat ohjeistuksen mukaiset',
    update: 'Päivitä',
    update_prebook: 'Päivitä varaus',
    your_changes_will_be_lost:
      'Haluatko varmasti keskeyttää? Kaikki muutokset menetetään.',
    settings: 'Asetukset',
    user_home_group_header: 'Oma toimipaikka',
    app_language: 'Kieli',
    update_settings: 'Tallenna asetukset',
    no_invoice_reference: 'En laskutusviitettä',
    no_user_group_selected: 'Kustannuspaikkaa ei valittu',
    prebook_successfully_updated: 'Laskutustiedot onnistuneesti vahvistettu',
    type_search_term_here: 'Kirjoita haettavan kustannuspaikan nimen osa tähän',
    verify: 'Vahvista',
    prebookings_successfully_updated:
      'Laskutustiedot onnistuneesti vahvistettu',
    alternative_cost_center: 'Tilaus- tai poikkeava kustannuspaikkanumero',
    alternative_cost_center_edit:
      'Muokkaa tilaus- tai poikkeavaa kustannuspaikkanumeroa',
    choose_default_cost_center: 'Valitse kotikustannuspaikka',
    enter_or_confirm_username: 'Anna tai vahvista kirjautumiseen käytettävä sähköpostiosoite.',
    enter_or_confirm_email: 'Anna tai vahvista sähköpostiosoite, johon lähetetään automaattisesti varausvahvistukset ja muutosilmoitukset varatuista tulkkauksista',
    you_can_change_this_information_at_any_time:
      'Voit koska tahansa muuttaa näitä tietoja ”Asetukset” -valikosta',
    edit: 'Muokkaa',
    change_reservation: 'Muokkaa',
    copy_reservation: 'Kopioi',
    change_reservation_description: 'Muokkaa varausta',
    copy_reservation_description: 'Kopioi varaus',
    cancel_prebook_description: 'Peruuta varaus',
    additional_cost_will_be_charged:
      'Tämä muokkaus on maksullinen. Alkuperäinen varaus alkaa 24 tunnin sisällä, joten veloitamme tästä muutoksesta hinnaston mukaisesti viime hetken peruutuksen verran.',
    we_will_cancel_this_booking_and_make_a_new_one:
      'Peruutamme tämän varauksen ja teemme sinulle uuden, jos muutat - aikaa, päiväystä, tyyppiä, kieltä tai kestoa.',
    preview: 'Esikatsele',
    edit_prebooking_details: 'Muokkaa varauksen laskutustietoja',
    no_invoices: 'Sinulla ei ole laskutukseen lähteviä tulkkausvarauksia.',
    add: 'Lisää',
    invite_success: 'Kutsu onnistuneesti lähetetty',
    invite_to_the_group: 'Kutsu uusi käyttäjä omaan yksikköösi',
    invite_to_the_group_explanation:
      'Tiedätkö kollegan jonka tehtäviin kuuluu tulkkivarausten tekeminen? Kutsu hänet Túlkaan.',
    invite: 'Lähetä kutsu',
    invite_to_group: 'Kutsu uusi käyttäjä',
    collaborators_header: 'Varausten yhteishallinnointi',
    add_collaborators: 'Jaa kollegalle hallinnointioikeus varauksiin',
    please_enter_email: 'Ole hyvä ja kirjoita sähköpostiosoite',
    collaborators_header_description:
      'Jaa kollegalle hallinnointioikeus varauksiisi. \nHuomioi, että hallinnointioikeuden voi jakaa vain jo olemassaolevan käyttäjän kanssa.',
    name: 'Etunimi ja Sukunimi',
    user_does_not_exist:
      'Käyttäjää ei löydy, voit jakaa oikeuden vain olemassaolevan käyttäjän kanssa.',
    invite_to_collaborate_success: 'Hallinnointioikeuden jako onnistui.',
    collaborator_successfully_removed:
      'Hallinnointioikeus onnistuneesti peruutettu.',
    invalid_email_address: 'Virheellinen sähköpostiosoite',
    invalid_phone_number: 'Hallinnointioikeus onnistuneesti peruutettu.',
    invalid_phone_number_error: 'Virheellinen puhelinnumero',
    already_registered_phone: 'Tämä numero on rekisteröity jo olemassa olevalle käyttäjälle. Ota yhteyttä asiakaspalveluun p. ',
    phone_number_cannot_start_with_0: 'Puhelinnumero ei voi alkaa 0:lla kansainvälisessä muodossa',
    email_address_from_different_organisation_domain:
      'Sähköpostiosoite kuuluu eri organisaatiolle.',
    this_user_already_added_to_collaborators:
      'Tällä käyttäjällä on jo hallinnointioikeus varauksiisi.',
    prebooked: 'Ennaltavarattu tulkkaus',
    ondemand: 'Pikatulkkaus',
    reset_user_password: 'Salasanan vaihto',
    reset_user_email: 'Vaihda sähköpostiosoite varausvahvistuksia varten',
    reset_user_email_for_login: 'Vaihda kirjautumiseen käytettävä sähköpostiosoite',
    please_enter_password: 'Salasana',
    please_enter_new_password: 'Uusi salasana',
    please_enter_current_password: 'Nykyinen salasana',
    please_repeat_password: 'Toista salasana',
    please_repeat_new_password: 'Uusi salasana uudestaan',
    passwords_do_not_match: 'Salasanat eivät täsmää',
    old_password_is_required: 'Nykyinen salasana on pakollinen',
    enter_new_password: 'Syötä uusi salasana',
    repeat_new_password: 'Uusi salasana uudestaan',
    password_requirement:
      'Salasanan tulee olla vähintään 8 merkkiä pitkä ja sisältää pieniä ja isoja kirjaimia sekä numero.',
    all_day: 'Koko päivä',
    previous: 'Edellinen',
    next: 'Seuraava',
    // today: 'Tänään',
    month: 'Kuukausi',
    week: 'Viikko',
    day: 'Päivä',
    agenda: 'Agenda',
    date: 'Pvm',
    // time: 'Aika',
    event: 'Tapahtuma',
    no_events_in_range: 'Ei tapahtumia jaksolla',
    show_more: 'Näytä lisää',
    reports: 'Raportointi',
    number_of_interpretations: 'Tulkkausmäärä',
    interpretation_breakdown_previous_month:
      'Tulkkauserittely - edellinen kuukausi',
    your_browser_is_not_supported: 'Käyttämäsi selain ei ole tuettu.',
    edge_browser_is_not_supported: 'EDGE-selain ei ole tuettu. Käytä Google Chrome -selainta.',
    we_recommend_using_the_latest_version_of_chrome_or_firefox_in_order_to_continue_using_this_service:
      'Suosittelemme käyttämään uusinta versiota Chrome tai Firefox selaimista jatkaaksesi tämän palvelun käyttämistä.',
    use_of_interpretation_report: 'Tulkkauksen Käyttö',
    billing_report: 'Laskutus',
    customer_reporting_report: 'Asiakasraportointi',
    data_export_report: 'Tiedon Vienti',
    monetra_billing_report: 'Monetra Laskutus',
    monetra_payroll_report: 'Monetra Palkanlasku',
    copy_data_to_new_booking: 'Käytä varausta uuden pohjana',
    update_at_least_date_or_time_fields_to_create_a_booking:
      'Luodaksesi varauksen muokkaa vähintään Päivä tai Kellonaika kenttiä',
    cancel_prebook: 'Peruuta varaus',
    newButton: 'Uusi',
    announcement_interpretation: 'Ilmoitustulkkaus',
    customer_name_and_date_of_birth: 'Asiakkaan nimi ja syntymäaika (laskulle)',
    customer_name: 'Asiakkaan nimi (laskulle)',
    date_of_birth: 'Asiakkaan syntymäaika (laskulle)',
    to_whom_the_message_is_forwarded_if_not_to_the_customer:
      'Kenelle viesti välitetään, jos ei asiakkaalle',
    telephone_number_to_which_the_interpreter_will_call:
      'Puhelinnumero(t), johon tulkki soittaa',
    main_telephone_number_to_which_the_interpreter_will_call:
      'Puhelinnumero, johon tulkki ensisijaisesti soittaa',
    additional_telephone_number_to_which_the_interpreter_will_call:
      'Muut puhelinnumero(t), joista asiakasta yritetään tavoittaa',
    message_to_customer_service_or_interpreter:
      'Viesti asiakaspalveluun tai tulkille',
    mandatory_information: 'Pakollinen tieto',
    optional_information: 'Vapaaehtoinen tieto',
    customer_data: 'Asiakkaan tiedot',
    write_the_content: 'Viestin sisältö',
    write_the_content_of_the_matter_to_be_reported_below:
      'Kirjoita ilmoitettavan asian sisältö alle',
    content_data_tooltip:
      'Ilmoitustulkkaus on yksisuuntainen, puhuttuna tarjottu viestin tulkkaus. Viestin pituus on maksimissaan 1000 merkkiä.',
    content_data_second_tooltip:
      'Tulkki ottaa yhteyttä ilmoitettuun puhelinnumeroon vähintään 5 kertaa. Ilmoitustulkkaus lasketaan suoritetuksi siitä huolimatta, ettei viesti saavuta asiakasta.',
    calendar_tooltip_notice_interpretation:
      'Tulkki soittaa ilmoitettuun numeroon valitun päivän loppuun mennessä.',
    calendars: 'Kalenterit',
    please_select_language: 'Valitse kieli',
    please_select_interpreter: 'Valitse tulkki',
    please_select_language_and_interpreter_to_see_the_calendar:
      'Valitse kieli ja tulkki ensin.',
    please_select_time: 'Valitse ajankohta poissaololle',
    block_time: 'Vahvistan poissaolon',
    block_time_description: 'Estetty aika - tänä aikana tulkki ei vastaanota soittoja eikä ennaltavarauksia',
    reservations_od: 'Varattu OD:lle',
    reservations_od_description: 'Varattu OD:lle - tänä aikana tulkki ei vastaanota ennaltavarauksia, ainoastaan OD soittoja',
    unavailable_time: 'Poissaolo',
    unavailability_type: 'Tyyppi',
    pick_start_time: 'Valitse aloitusaika',
    pick_end_time: 'Valitse lopetusaika',
    time_successfuly_blocked: 'Poissaolo onnistuneesti kirjattu',
    time_successfuly_unblocked: 'Poissaolo onnistuneesti peruutettu',
    time_successfuly_updated: 'Poissaolo onnistuneesti päivitetty',
    overlaping_information_dialog_mesage:
      'Valittu ajankohta osuu osittain päällekkäin olemassaolevan varauksen tai aiemman poissaolomerkinnän kanssa. Valitse uusi ajankohta tai peruuta aiempi varaus tai merkintä.',
    ok: 'ok',
    cancel_blocked_time_confirmation_message:
      'Oletko varma että haluat peruuttaa poissaoloilmoituksen?',
    cancel_interpreter_blocked_time: 'Poista sulku',
    relay_the_message_to_person: 'Viesti välitetään henkilölle:',
    birthday: 'Asiakkaan syntymäaika:',
    numbers_to_be_called: 'Numero(t) johon tulkki soittaa:',
    message: 'Viesti:',
    has_to_be_a_phone_number: 'Täytyy olla muotoa puhelinnumero',
    other_information_relevant_to_the_invoicing_such_as_information_about_the_customer:
      'Muu laskuntarkastajalle olennainen tieto, kuten tieto palvelunkäyttäjästä',
    this_invoice_needs_additional_information:
      'Ole hyvä ja täytä vähintään pakolliset laskutustiedot',
    cancelled_by: 'Peruuttaja',
    cancel_reason: 'Peruutuksen syy',
    join_to_meeting: 'Liity kokoukseen',
    ready_to_join: 'Valmis liittymään?',
    what_is_your_name: 'Mikä on nimesi?',
    your_name: 'Nimesi',
    waiting_for_luona_worker_and_interpreter:
      'Odotetaan Luonan työntekijää ja tulkkia…',
    waiting_label: 'Odotetaan…',
    type_message: 'Kirjoita viesti',
    looking_for: 'Hyvän palvelun takaaminen vaatii joskus aikaa. Odota hetki. Etsitään',
    luona_worker_persone: 'Luonan työntekijä',
    interpreter_persone: 'Tulkki',
    interpreter_status: 'Käytettävissä nyt',
    availability_yes: 'Käytettävissä nyt',
    availability_no: 'Et ole käytettävissä.',
    // prebooking luona
    prebooking_page_title: 'Varaukset',
    defaultPrebookingPageMessage: 'Sinulla ei ole varauksia',
    go_to_prebookings_list: 'Siirry varauslistaan',
    luona_feedback_title: 'Laatukysely',
    luona_feedback_description: 'Olet hyödyntänyt Luonan uuttaa teknistä etäpalvelualustaa. Keräämme palautetta palvelusta ja olisimme kiitollisia, jos vastaisit lyhyeen kyselyyn.',
    luona_feedback_score: 'Kuinka todennäköisesti suosittelisit tätä etäpalvelualustaa ystävällesi?',
    luona_feedback: 'Palaute',
    feedback_sent: 'Kiitos palautteesta!',
    luona_prefer: 'Etuudet',
    Luona_lead: 'Ryhmänvetäjä',
    luona_social: 'Sosiaali',
    luona_nurses: 'Terveys',
    go_to_services: 'Siirry palveluun',
    start_prebook: 'Aloita',
    prebooking_is_gone: 'Varausta ei enää ole',
    prebooking_not_answered: 'Emme valitettavasti pysty toimittamaan ennakkovaraustasi. Varaa uusi tapaaminen asiantuntijan kanssa.',
    prebooking_is_not_found: 'Aktiivista varausta ei löytynyt. Varaa uusi tapaaminen asiantuntijan kanssa.',
    prebooking_is_cancelled: 'Aktiivista varausta ei löytynyt. Varaus on mahdollisesti peruttu. Varaa uusi tapaaminen asiantuntijan kanssa.',
    prebooking_is_too_late: 'Varatusta ajasta on jo niin kauan, että tulkki on siirtynyt toiseen puheluun. Varaa uusi tapaaminen asiantuntijan kanssa.',
    prebooking_will_start_in: 'Ajanvarauksesi alkuun on:',
    early_preview_intro: ' Arvoisa Luonan asiakas!',
    you_can_close_tab_for_now: 'Voit sulkea tämän välilehden toistaiseksi.',
    my_prebooking_list: 'Omat varaukseni',
    waiting_time: 'Odotusaika on',
    connecting_to_meeting: 'Yhdistetään tapaamiseen',
    connecting_to_meeting_for_interpreter: 'Odota hetki, yhdistämme kaikki osallistujat.',
    no_access_to_your_camera_or_microphone: 'Et ole antanut lupaa käyttää laitteesi kameraa tai mikrofonia',
    remote_backup_to_onsite: 'Kuinka toimitaan, jos tulkkia ei löydy paikan päälle?',
    use_remote_interpretation_option: 'Käytetään etätulkkausta',
    cancel_prebooking_option: 'Perutaan tulkkaus',
    which_remote_interpretation_type_do_you_choose: 'Kumman etätulkkausmuodon valitset?',
    phone_interpretation: 'Puhelintulkkaus',
    conference_virtual_room: 'Neuvottelu / Virtuaalihuone (esim. Google Meet, Teams, Zoom)',
    confirm_you_are_online: 'Oletko poistunut? Jos haluat jatkaa puhelujen vastaanottoa, ole ystävällinen ja klikkaa "Liity takaisin" -painiketta.',
    join_back: 'Liity takaisin',
    requestErrorMessageReasonPoorInternet: 'Hupsista! Jotain meni pieleen. Syy saattaa olla huonossa nettiyhteydessä. Tarkasta yhteysasetukset!',
    networkDisconnectedMessage: 'Nettiyhteytesi ei ole riittävän nopea video-puheluille. Tarkasta yhteysasetukset!',
    interpreter_name: 'Tulkki',
    meeting_disconnected: 'Yhteys katkesi',
    meeting_reconnecting: 'Yhdistetään uudelleen',
    meeting_reconnected: 'Yhteys palautettu',
    browser_does_not_support_video_effects: 'Selaimesi ei tue videotehosteita',

    // Self-selfRegistration
    user_group_not_found: 'Emme voi suorittaa rekisteröintiäsi tällä hetkellä, koska organisaatioltasi puuttuu vaaditut asetukset. Ota yhteyttä asiakaspalveluumme saadaksesi apua tämän ongelman ratkaisemisessa.',
    log_in: 'Kirjaudu sisään',
    sign_up: 'Rekisteröidy',
    create_an_account: 'Luo käyttäjätunnus',
    contact_information: 'Yhteystiedot',
    first_name: 'Etunimi',
    last_name: 'Sukunimi',
    contact_info_email: 'Sähköposti',
    contact_info_phone: 'Puhelinnumero',
    continue: 'Seuraava',
    is_personal_email: 'Sähköpostiosoitetta käytetään henkilöllisyytesi vahvistamiseen. Vahvista onko tämä sähköposti vain henkilökohtaiseen viestintääsi vai jaettu työtovereiden kanssa:',
    email_for_personal: 'Sähköposti on vain henkilökohtaiseen viestintääni',
    email_shared_with_colleagues: 'Sähköposti on jaettu työtoverien kanssa',
    check_email_again: 'Syötä sähköpostiosoite uudelleen vahvistussähköpostin lähettämistä varten.',
    is_personal_phone: 'Puhelinnumeroa käytetään henkilöllisyytesi vahvistamiseen. Vahvista onko tämä puhelinnumero vain henkilökohtaisessa käytössä vai jaettu työtovereiden kanssa:',
    phone_for_personal: 'Puhelinnumero on vain henkilökohtaisessa käytössä',
    phone_shared_with_colleagues: 'Puhelinnumero on jaettu työtoverien kanssa',
    check_phone_again_by_sms: 'Syötä puhelinnumero uudelleen vahvistustekstiviestin lähettämistä varten.',
    check_phone_again_by_call: 'Syötä puhelinnumero uudelleen automaattista soittoa varten.',
    already_have_an_account_part1: 'Onko sinulla jo tili? ',
    already_have_an_account_part2: 'Kirjaudu sisään ',
    confirm_your_email: 'Vahvista sähköpostiosoitteesi',
    confirm_your_email_message: 'Rekisteröinnin vahvistamiseksi olemme lähettäneen rekisteröityyn sähköpostiin vahvistusviestin. Vahvista sähköpostiosoite viestissä annettujen ohjeiden mukaan.',
    confirm_your_phone: 'Vahvista puhelinnumero',
    confirm_your_mobile_phone_message: 'Rekisteröinnin viimeistelemiseksi olemme lähettäneet vahvistusviestin rekisteröityyn puhelinnumeroon. Vahvista puhelinnumero noudattamalla tekstiviestissä olevia ohjeita.',
    confirm_your_phone_message: 'Automaatti soittaa sinulle pian lankapuhelinnumeron omistajuuden vahvistamiseksi. Kuulet puhelussa koodin, joka sinun tulee syöttää tälle rekisteröintilomakkeelle.',
    confirmation_code: 'Vahvistuskoodi',
    didnt_receive_an_email: 'Etkö saanut sähköpostia?',
    didnt_receive_a_call: 'Etkö saanut puhelua?',
    didnt_receive_sms: 'Etkö saanut vahvistusviestiä?',
    send_email_again: 'Lähetä sähköposti uudelleen',
    send_phone_again_for_sms: 'Lähetä uusi vahvistustekstiviesti',
    send_phone_again_for_call: 'Pyydä uutta soittoa',
    verification_page: 'Vahvistus',
    verification_link_expired: 'Jotain meni pieleen. Vahvistuslinkki on mahdollisesti päässyt vanhentumaan. Yritä uudelleen.',
    waiting_for_verification: 'Odotetaan vahvistusta', 
    select_the_correct_cost_center_regarding_invoicing: 'Valitse alla olevasta listasta oikea laskutusyksikkö tai kustannuspaikka. Mikäli et löydä oikeaa laskutusyksikköä valitse “En löytänyt oikeaa laskutusyksikköä” -valinta',
    how_often_do_you_predict_to_utilize_interpretation_services: 'Kuinka usein arvioit keskimäärin tarvitsevasi tulkkauspalveluita?',
    daily: 'Päivittäin',
    weekly: 'Viikottain',
    more_than_weekly: 'Harvemmin',
    periodically: 'Tarve on jaksottaista',
    cost_center: 'Laskutusyksikkö',
    i_could_not_find_the_correct_cost_center: 'En löytänyt oikeaa laskutusyksikköä',
    information_regarding_cost_center: 'Tiedot laskutusyksiköstä (toimiala sekä osastosi / yksikkösi, jolla työskentelet):',
    please_tell_us_where_you_work_in_which_unit_etc: ' Syötä kenttään toimiala sekä osastosi / yksikkösi, jolla työskentelet.',
    update_user_home_group_failed: 'Laskutusyksikön päivitys epäonnistui',
    set_up_your_password: 'Luo salasana',
    repeat_password: 'Vahvista salasana',
    submit: 'Rekisteröidy',
    set_password_failed: 'Salasanan päivitys epäonnistui',
    account_created: 'Käyttäjätunnus luotu!',
    you_can_now_login_to_the_tulka_application_using_your_credentials: 'Voit nyt kirjautua Túlkaan käyttäjätunnuksellasi. Sivun alareunasta löytyvällä painikkeella pääset suoraan Túlkan selainversioon.',
    we_have_also_sent_you_instructions_for_use_by_email: 'Olemme myös lähettäneet sinulle käyttöohjeet sähköpostiin.',
    open_mobile_app: 'Avaa mobiilisovellus',
    login_to_web: 'Kirjaudu sisään selainversioon',
    account_found: 'Olemassa oleva tunnus löytynyt',
    please_log_in_or_request_a_new_password: 'Kirjaudu sisään tai pyydä uusi salasana.',
    forgot_password: 'Unohdin salasanan',
    no_contract_found: 'Sopimusta ei löytynyt',
    it_seems_like_you_dont_have_an_contract_with_tulka_based_on_your_email_address: 'Emme löytäneet olemassa olevaa sopimusta antamallasi sähköpostiosoitteella.',
    please_contact_our_sales_to_begin_using_tulka: 'Mikäli haluat Túlkan käyttöön ota yhteyttä asiakaspalveluumme sivun oikeasta alareunasta olevan chatin kautta, puhelimitse 030 660 6400 tai sähköpostitse info@tulka.com',
    email_already_registered: 'Antamallasi sähköpostiosoitteella on jo olemassa oleva tunnus.',
    contact_service_with_chat: 'Ota yhteyttä asiakaspalveluumme sivun oikeasta alareunasta löytyvän chatin kautta tai soittamalla numeroon ',
    contact_service_without_chat: 'Ota yhteyttä asiakaspalveluumme p. ',
    contact_sales: 'Ota yhteyttä myyntiin',
    invalid_code: 'Virheellinen koodi',
    invalid_field_value: 'Tämä kenttä on pakollinen, eikä se voi olla tyhjä.',
    fistName_requirement: 'Etunimi pakollinen',
    lastName_requirement: 'Sukunimi pakollinen',
    contact_service_contacts: 'puhelimitse 030 660 6400 tai sähköpostitse info@tulka.com',
    backup_option: 'Varavaihtoehto',
    no_backup_option_selected: 'varavaihtoehtoa ei ole valittu',
  },

  en: {
    // feedback
    failed_to_find_prebooking_data: 'Failed to find this prebooking',
    prebooking_was_cancelled: 'This prebooking was cancelled',
    feedback_not_open_yet: 'As the interpretation hasn\'t taken place yet, you are unable to leave us feedback at this time.',
    how_did_we_do: 'How was your experience?',
    stars_rating_subtitle: 'Your feedback helps us to improve our service.',
    where_can_we_improve_upon: 'How could we improve?',
    leave_open_feedback: 'Give free text feedback',
    describe_your_experience_here: 'Tell what happened in your own words',
    i_wish_to_be_contacted: 'Please contact me',
    choose_one_or_more_options: 'Choose one or more options',
    thank_you_for_your_feedback: 'Thank you for your feedback!',
    // customer guide for od-call (modal)
    if_neither_number_works: ' If neither number works, enter the service number in the following format:',
    order_an_interpreter_by_calling_the_number: 'Order an interpreter by calling the number',
    main_calling_number: '041 400 1234',
    main_calling_number_with_code: '*31# 041 400 1234',
    main_calling_number_with_zero: '0041 400 1234',
    phone_must_be_registered: 'The phone you use to call our emergency number must be registered in our system.',
    note: 'Note!',
    number_is_encrypted: ' If your number is encrypted, enter the service number in the following format:',
    attention_for_hus: 'Attention HUS wired network operators!',
    contact_to_customer_service: 'In case of problems, contact our customer service.',
    select_interpreter_language: 'After the call is connected, select the language to be interpreted from the following by entering the number using the phone\'s number keys',
    accept_incoming_prebookings: 'Accept or decline incoming prebookings',
    accept: 'Accept',
    decline: 'Decline',
    accept_all: 'Accept all',
    date_and_time: 'Date and time',
    duration: 'Duration',
    language: 'Language',
    presence: 'Presence',
    prebookings_already_checked: 'Prebookings already checked',
    username_not_unique: 'Login email is not unique.',
    new_username_set: 'New username set: ',
    // LUONA
    luonaSignupForm: 'Signup form',

    label_name: 'First name and last name',
    placeholder_name: 'Latin letters only',
    validate_name: 'More than 3 characters',

    label_phone: 'Phone',
    placeholder_phone: '+3XXXXXXXXXXX',
    validate_phone: 'mobile phone number in format: +XXXXXXXXXXXX',

    label_email: 'Email',
    placeholder_email: 'user@mail.com',
    validate_email: 'email in format: user@mail.com',

    label_city: 'City',
    placeholder_city: 'City name',
    validate_city: 'Only latin letters',
    selectOption: 'Select city',

    agreePrivacyPolicy: 'I agree with ',
    privacyPolicy: 'privacy policy',
    luonaSignupButton: 'Send',
    requestErrorMessage: 'Something went wrong, please try again!',
    minimalPrebookingTimeBumperError: 'Prebooking time is too soon. Please select another time.',
    new_password_success: 'New password successfully added',
    new_password_wrong: 'Old password not found',
    requestErrorMessageUserNotFound: 'Enter the registered phone number and email address.',
    requestErrorMessageUserExist:
      'Provided email or phone number is already in use, please try another one.',
    slot_not_free_anymore: 'Sorry, selected time slot is unavailable anymore',
    no_interpreter_in_prebooking: 'No interpreter in prebooking',
    back_to_telegram: 'Back to Telegram',
    what_is_your_language: 'Hello, What is your language',
    what_you_need_help_with: 'What do you need help for?',
    selectable_langs: 'Language',
    selectable_servises: 'Services',
    selectable_time: 'Time',
    selectable_interpreter: 'The consultation language is English or Finish. Do you need an interpreter?',
    authtorize: 'Authorization',
    choose_time: 'Please choose the time for your prebooking',
    prebook_not_available_message: 'Unfortunately, this service is unavailable right now. The working time:  Monday to Friday {SCHEDULE}',
    health: 'Health',
    work_or_study: 'Work or studying',
    money_or_livelihood: 'Money or livelihood',
    reception_allowance: 'Reception allowance / PFS card',
    my_money_is_not_enough: 'Running out of money',
    copy_email: 'Copy email',
    successfully_copied: 'E-mail successfully copied',
    other_practical_matters: 'Other practical topic',
    thanks_for_booking: 'Thank you for your reservation.',
    prebook_created_at: 'Your video meeting has been confirmed as following:',
    prebooking_skill: 'Issue:',
    you_need_high_network: 'To ensure the quality of the meeting, please make sure you have a stable and fast network connection, enough for video conference.',
    you_will_got_accept: 'You will receive a booking confirmation and two reminders by text message and email.',
    message_with_link_to_meeting: 'The messages contain a link from which you can get to the meeting at the agreed time.',
    dont_forget_decline_prebooking: 'Remember to cancel the meeting if you are not able to participate.',
    for_decline_need: 'You can do it in:',
    click_for_decline_prebooking: 'and then click on DELETE button on your pre-booking.', 
    best_regards: 'Kindest wishes,',
    luona_DigiVOK: 'Luona DigiVOK',
    emigration_or_holiday_outside_Finland:
      'Emigration or vacation outside Finland',
    free_time: 'Leisure time',
    housing: 'Living',
    child_care: 'Child',
    child_health: 'Health',
    upbringing_parenting: 'Upbringing/Parenthood',
    kindergarten_or_school: 'Daycare or school',
    reception_centre_info_service: 'Reception centre info service',
    physical_or_mental_symptoms: 'Health',
    related_to_money_or_livelihood: 'Financial security',
    related_to_your_own_or_family_life_situation:
      'Circumstances related to myself or my family',
    legal_matter: 'Legal affair',
    everyday_practical_things: 'Daily, everyday practical things',
    service_housing: 'Living',
    how_do_you_judge_the_criticality_of_your_case:
      'How do you rate the criticality of your case?',
    help_need_today: 'Urgent, should be taken care of during today',
    help_need_in_couple_of_days: 'Urgent, but can wait for few days',
    help_is_not_urgent: 'Not urgent',
    call_now: 'Call now',
    book_an_appointment: 'Book an appointment',

    help_need_in_week: 'Help need during week',
    authtorize_yourself: 'Authtorize yourself',
    user_successfully_registered: 'Authorization is successful',
    book_time: 'Book Time',
    what_time_works_for_you: 'What time works for you?',
    wizard_default_message:
      'If the situation is life threatening, call 112. If your health concern cannot wait till the next working day, call 116117 for advice. If you have another acute crisis, call the social crisis service of your municipality.',
    wizard_welcome_message:
      'Welcome to Luona service! We are going to do everything that we can to help you. How we can help you?',
    wizard_PrebookingStep_header:
      'We strive to deal with situations immediately. If all our employees are booked, you will have the opportunity to book an appointment through this. Would you like to book an appointment or call right now?',
    wizard_TimeStep_header:
      'Unfortunately, no one was able to answer now. You can book time from here.',
    // LUONA

    loginTitle: 'Sign in',
    username: 'Username',
    password: 'Password',
    loginButton: 'Sign in',
    loginFailed: 'Signin failed',
    sessionExpired: 'Session has expired. Please sign in again.',
    meeting_closed: 'Meeting was closed.',
    signin_code_block_message: 'This e-mail is already used before. We sent the verification code to your e-mail address: [email]. Please enter the verification code in the field below. ',
    placeholder_signin_code: 'code',
    close_meeting_confirmation_message: 'Are you sure that you want to leave the call? For all participants, this call will be closed too',
    internet_connection_lost: 'Lost internet connection',
    internet_connection_exist: 'Restored internet connection',
    errorTitle: 'Oops!',
    warnTitle: 'Hmmm...',
    successTitle: 'Great!',

    selectLanguage: 'Choose language',
    interpreter: 'Interpreter',
    startInterpreting: 'Interpret',
    requestInterpreting: 'Request interpretation',

    interpretationNeeded: 'Interpretation needed',
    requestForInterpretation: 'Interpretation is needed!',
    requestForCall: 'Customer is contacting',
    nothingToInterpret: 'All requests have been answered.',
    noInterpretationNeeded: 'All requests have been answered.',

    doYouWantToHangUp: 'Do you really want to hang up?',
    oppositeSideHangsUp: 'The other person has left the conversation.',
    networkTerminated: 'The conversation was disconnected.',
    review: 'Review',
    customerFeedbackEnjoyingTulka: 'Satisfied with the service?',
    customerRatingInterpreter: 'My feedback about interpreter',
    customerInvoiceNotes: 'Invoice reference',
    interpreterRatingInterpreter: 'How did it go?',
    interpreterRatingCustomer: 'How well did I do?',
    ratingQuality: 'Technical quality',
    feedback: 'Feedback',
    feedbackDesc: 'Write your comments here',
    feedbackContactMe: 'Please contact me',
    proceed: 'Send',
    skip: 'Skip',

    endCall: 'End call',
    refresh: 'Refresh',
    logout: 'Log out',
    toggleCamera: 'Toggle camera',
    toggleAudio: 'Toggle audio',

    onlyChromeSupported: 'Browser not supported',
    pleaseUseChrome: 'Please install Google Chrome to use Túlka Web App',
    downloadChrome: 'Click here to install',
    continueInterpretation: 'Continue interpretation',
    roomInfoCallEndedClosed: 'Server closed the conversation.',
    feedbackTooLate: 'Continue option no longer available!',

    noTimeLeft: 'The demo use has ended. We`ll contact you soon!',
    minutesConsumed: 'The demo use has now ended. We`ll contact you soon!',
    timeLeft: 'Interpretation time left: {}',
    zeroTimeLeft: 'The demo use has ended.\nWe`ll contact you soon!',
    freeUseLeft: 'Time left',

    failedToSendFeedback: 'Failed to send feedback!',
    failedLogOut: 'Failed to log out.',
    failedToCreateChatroom: 'Failed to start an interpretation!',
    failedToConnect: 'Failed to connect to server.',
    waitingForInterpreter:
      'Your request was sent to the interpreters. The waiting time is usually 1 to 3 minutes.',
    waitingForCustomer: 'Waiting for customer...',
    waiting_time: 'Waiting time is',
    pleaseWait: 'Please wait...',
    youHaveWaitedFor: 'You`ve waited {}',
    error500: 'Something went wrong. Please try again.',
    roomInfoHasAudioPacketLoss: 'Bad Connection!',

    yes: 'Yes',
    no: 'No',
    cancel: 'Cancel',

    default: 'Default',
    termsOfUse: 'Terms of use & Privacy policy',
    options: 'Show options',
    hideOptions: 'Hide options',

    enable_notifications: 'Enable notifications',
    disable_notifications: 'Disable notifications',
    disable_duration: 'Disable notifications for',
    for_hour: '1 hour',
    for_day: '1 day',
    for_week: '1 week',
    for_month: '1 month',
    for_a_day: '1 day',
    for_days: '{} days',
    for_a_day_an_hour: '1 day, 1 hour',
    for_a_day_hours: '1 day, {} hours',
    for_days_an_hour: '{} days, 1 hour',
    for_days_hours: '{d} days, {h} hours',
    notifications_disabled_for: 'Notifications disabled for\n{}',
    failed_to_disable_notifications:
      'Failed to disable notifications. Please try again later.',
    failed_to_enable_notifications:
      'Failed to enable notifications. Please try again later.',
    forgot_password_link: 'Forgot your password?',
    forgot_password_info:
      'If you have an account, we can send you a new password via SMS or email. In other cases contact info@tulka.com.',
    placeholder_phone_number: '+35840...',
    phone_number_title: 'Phone number',
    forgot_password_error:
      'The SMS or e-mail could not be sent. Please contact info@tulka.com',
    forgot_password_success:
      'Thank you. You should receive your password via SMS or e-mail shortly.',
    skillModalQuestion:
      'Do you need this experience? The waiting time may be longer.',
    targetLanguage: 'Language you understand',
    openingTimes: '',
    systemAlert: 'Technical issues with the service. Our apologies.',

    chatFailed: 'Failed to connect to text chat.',
    chatTipInterpreter:
      'Here you can chat with the customer. All messages are destroyed immediately when the interpretation ends.',
    chatTipCustomer:
      'Here you can chat with the interpreter. All messages are destroyed immediately when the interpretation ends.',
    chatSendFailed: 'Failed to send the message.',
    chatTypeMessage: 'Type a message...',
    invoiceRefMandatory: 'Invoice reference is a mandatory field.',
    invoiceRefUserGroupMandatory: 'Please select a recipient for the invoice.',
    selectInvoiceRefUserGroup: '-- Select --',

    show_feedbacks: 'Ratings',
    show_feedbacks_title: 'How I was rated',
    failedToFetchFeedbacks: 'Failed to fetch the ratings.',
    statistics: 'My interpretations',
    no_statistics: 'No statistics available.',
    stats_disclaimer:
      'The numbers are only estimates and may not exactly match the final pay slip.',
    stats_today: 'Today',
    stats_yesterday: 'Yesterday',
    stats_this_week: 'This week',
    stats_last_week: 'Last week',
    stats_this_month: 'This month',
    stats_last_month: 'Last month',
    stats_this_year: 'This year',
    stats_last_year: 'Last year',

    feedbackButton: 'Tap to write comments',
    feedback_already_exist: 'Feedback is closed',
    feedback_need_to_leave: 'Feedback is open',
    feedback_do_next: 'You may now close this tab.',
    chatroom_not_exist: 'Feedback not yet open',

    // Prebooking
    prebook: 'Prebooked Interpretation',
    prebooking_date_header: 'When do you need an interpreter?',
    prebooking_date_header_notice: 'Last date for the message to be delivered',
    step: '{step} of {steps}',
    choose_button: 'Select',
    prebooking_duration_header:
      'How long do you think you need an interpreter?',
    hours_minutes: '{h}:{m}',
    hour_minutes: '{h}:{m}',
    minutes: '{} min',
    hour: '60 min',
    hours: '{}:00',
    customer_reference: 'Additional invoicing information',
    customer_reference_placeholder: 'The field information is not visible to the interpreter',
    please_provide_customer_reference: 'Please provide additional information.',
    prebooking_time_header: 'At what time do you need an interpreter?',
    select_language_title: 'What should be the interpreter language?',
    fetching_languages: 'Fetching languages...',
    invoice_cause: 'Cost Allocation',
    invoice_cause_description: 'Please select appropriate cost center',
    invoice_ref_placeholder: 'NOTE! Customer service does not read this.',
    call_type_video: 'Video',
    call_type_call: 'Call',
    call_type_question: 'Please choose the connection type',
    additional_order_info: 'Additional order information',
    confirm_prebooking: 'Please check the booking details.',
    comment: 'Necessary additional information for order handler',
    prebooking_date: 'Date',
    prebooking_time: 'Time',
    prebooking_duration: 'Duration',
    language_title: 'Language',
    presence_title: 'Presence',
    call_type_title: 'Call type',
    select_target_language_title: 'What is your language?',
    location_question: 'What is the location?',
    location_placeholder_city: 'City',
    location_placeholder_address: 'Street address',
    location_placeholder_postcode: 'Postcode',
    add_message_to_interpreter: 'Add message to interpreter',
    subject: 'Subject',
    interpretation_location_details: 'Arrival instructions at the location (e.g. office, stairs, floor, room, etc.)',
    address_info: 'Address information',
    arrival_instructions: 'Arrival instructions',
    addressAndArrivalInstrustions: 'Address and arrival instructions',
    preferred_interpreters_question: 'Would you like to select a preferred interpreter?',
    search_preferred_interpreter: 'Find interpreter by name',
    not_guarantee: 'We do our best to get the wished interpreter, but cannot guarantee that he/she is available.',
    history_is_limited: 'The search result is limited because you haven\'t used many interpreters yet.',
    presence_question: 'What type of interpretation?',
    presence_remote: 'Remote',
    presence_remote_tip: 'Via video or call using the app',
    presence_on_site: 'On-site',
    presence_on_site_tip: 'Interpreter physically present',
    presence_notice: 'Announcement interpretation',
    presence_notice_tip: 'Written message delivered as spoken interpretation',
    select_favorite: 'Want to select preferred interpreters?',
    any_favorite: 'No preference',
    call_type_call_tip: 'Voice only using a phone call',
    call_type_video_tip: 'Voice and image using mobile data',
    target_language_title: 'Your working language',
    invoice_ref_title: 'Invoice tag',
    save_prebooking: 'Book',
    edit_prebooking: 'Save',
    any_gender: 'Does not matter',
    any_dialect: 'No specific needs',
    gender_title: 'Gender',
    dialect_title: 'Dialect',
    select_skill: 'Any special needs for the interpreter?',
    select_dialect: 'Do you need a specific dialect?',
    select_gender: 'Does the interpreter gender matter?',
    any_skill: 'No special needs',
    skill_title: 'Special needs',
    unconfirmed_prebooking: 'Pending confirmation',
    confirmed: 'Confirmed',
    delivered: 'Delivered',
    not_able_to_reach: 'Not able to reach',
    cancelled: 'Cancelled',
    active: 'Active',
    location_title_city: 'City',
    location_title_address: 'Street address',
    // Help tooltips
    help_prebooking_summary_title: 'Write here only necessary additional information for order handler',
    help_prebooking_summary:
      'Note that filling this field will prolong processing time of the order.',
    help_call_type_title: 'Interpretation connection',
    help_call_type:
      'With a telephone interpretation you are able to join people who are in different locations (conference call).\n\nIn a video interpretation, people can see each others, and you can also show some text to the interpreter to translate. Video can be enabled or disabled any time during the interpretation.\n\nYou can change the interpretation connection by ending the interpretation and selecting \'Continue interpreration\'.',
    help_gender_title: 'Interpreter gender',
    help_gender:
      'You can request an interpreter of a specific gender. Please note that the selection may result a smaller number of available interpreters and make it less likely to get one.',
    help_dialect_title: 'Interpreter dialect',
    help_dialect:
      'You can request an interpreter who knows a specific dialect of the selected language. Please note that the selection may result a smaller number of available interpreters and make it less likely to get one.',
    help_confirmation_title: 'Confirm your default cost center',
    help_confirmation:
      'Your orders will be allocated by default to your default cost center, unless you specify an alternative cost center while placing the order.',
    tomorrow: 'Tomorrow',
    today: 'Today',
    now: 'Now',
    help_location_title: 'Location',
    help_location:
      'Type the location of the interpretation. This can be an address or other identifier.',
    help_favorites_title: 'Preferred interpreters',
    help_favorites:
      'You can select one or more of you favorite interpreters as the preferred interpreters. If one of the favorites is available he/she will be assigned to the interpretation.',
    on_site_not_available_in_app:
      'On-site interpretation cannot be started from the app.',
    searching_interpreter: 'Looking for interpreter',
    please_call_to_this_number: 'Please, call to this number',
    back: 'Back',
    rejoin_meeting: 'Rejoin meeting',
    change_language: 'Change language',
    prebook_successfully_created: 'Prebooking successfully created',
    prebook_successfully_edit: 'Prebooking successfully updated',
    prebook_successfully_cancelled: 'Prebooking successfully cancelled',
    prebook_successfully_accepted: 'Prebooking successfully accepted',
    prebooking_datails: 'Prebooking details',
    prebook_confirmation_message:
      'Are you sure that you want to abort prebooking?',
    cancel_prebook_confirmation_message:
      'Are you sure that you want to cancel prebooking?',
    really_cancel_prebooking_too_late:
      'Are you sure that you want to cancel prebooking? At this stage, cancelling is not free.',
    cancel_reason_selection: 'What is the reason for cancelling?',
    cancel_reason_sickness: 'Own sickness',
    cancel_sickness_tip: 'Medical certificate required',
    cancel_reason_mistake: 'Mistake',
    cancel_reason_other: 'Other',
    cancel_reason_other_tip: 'A free text explanation required.',
    cancel_other_title: 'Explanation',
    cancel_other: 'Tell us what happened?',
    cancel_sickness_title: 'Medical certificate',
    cancel_sickness: 'Please send a medical certificate of the sickness.',
    copy_information: 'Copy Information',
    my_bookings: 'Bookings',
    interpreter_now: 'Interpreter now',
    pre_book: 'Prebooked Interpretation',
    time: 'Time',
    status: 'Status',
    search: 'Search',
    favorite_title: 'Preferred interpreters',
    no_prebookings: 'You don`t have any prebookings.',
    prebooking_not_active: 'Your contract does not include prebooking.',
    reservation_number: 'Reservation number',
    email: 'E-mail',
    not_yet_support_interpreter_login:
      'The new web app does not yet support interpreter login. Please use the mobile app.',
    please_try_again_later: 'Please try again later.',
    no_languages: 'No languages',
    interpreter_phone_number: 'Interpreter phone number',
    remove: 'Delete',
    presence_conference: 'Conference / virtual room',
    presence_conference_tip: 'e.g. Microsoft Teams, Skype, Google Meet, Zoom',
    interpreter_email: 'Interpreter email',
    all: 'All',
    invoice_user_group: 'Cost center',
    cc_emails: 'Confirmation and change notifications recipients',
    cc_email: 'E-mail address',
    cc_emails_copy: 'Сonfirmation and change notification recipients',
    patient_name: 'Your client\'s name who needs the interpreter',
    patient_name_description: 'This information does not appear on the bill, but goes to the interpreter for information, e.g. in case of detention.',
    customer: 'Customer',
    customer_unit: 'Unit',
    personal: 'Personal',
    search_for: 'Search for unit, language, cost center or customer',
    failed_to_create_prebooking:
      'Failed to create a new prebooking. Please try again later.',
    to_start_interpretation:
      'To start interpretation please call to this number:',
    start_interpretation: 'Start Interpretation',
    hey_username: 'Hey {username},',
    confirm_your_account_information: 'Confirm your account information',
    confirm_your_account_information_description:
      'In order to provide the smoothest possible service, we ask you to confirm or correct the information below regarding your profile',
    other: 'Other',
    no_options: 'No search results',
    search_message: 'Search...',
    i_confirm_the_information: 'I CONFIRM THE INFORMATION',
    billing: 'Invoicing',
    checking_your_billing_information: 'Checking your billing information',
    check_that_the_interpretation_is_for_the_correct_cost_center:
      'Check that the interpretation is for the correct cost center / unit and that the billing reference information is in accordance with the instructions',
    update: 'Update',
    update_prebook: 'Update prebooking',
    your_changes_will_be_lost:
      'Are you sure you want to cancel? Your changes will be discarded.',
    settings: 'Settings',
    user_home_group_header: 'Home user group',
    app_language: 'Language',
    update_settings: 'Save settings',
    no_invoice_reference: 'No invoice reference',
    no_user_group_selected: 'No user group selected',
    prebook_successfully_updated: 'Invoicing information confirmed',
    type_search_term_here: 'Type search term here',
    verify: 'Confirm',
    prebookings_successfully_updated: 'Invoicing information confirmed',
    alternative_cost_center: 'Alternative cost center or order number',
    alternative_cost_center_edit:
      'Edit alternative cost center or order number',
    choose_default_cost_center: 'Choose default cost center',
    enter_or_confirm_username: 'Enter or confirm the email used for login',
    enter_or_confirm_email: 'Enter or confirm an e-mail address to which booking confirmations and change notifications for booked interpretations are automatically sent',
    you_can_change_this_information_at_any_time:
      'You can change this information at any time from the “Settings” menu',
    edit: 'Edit',
    change_reservation: 'Change',
    copy_reservation: 'Copy',
    change_reservation_description: 'Change reservation',
    copy_reservation_description: 'Copy reservation',
    cancel_prebook_description: 'Cancel reservation',
    additional_cost_will_be_charged:
      'Additional cost will be charged. Original scheduled time takes place within 24 hours, so this change will incur an additional charge as per last minute cancellation in the price list',
    we_will_cancel_this_booking_and_make_a_new_one:
      'We will cancel this booking and make a new one if you change - time, date, type, language, duration',
    preview: 'Preview',
    edit_prebooking_details: 'Edit Prebooking details',
    no_invoices: 'You don`t have any bookings to process for invoicing.',
    add: 'Add',
    invite_to_the_group: 'Invite to the group',
    invite_to_the_group_explanation:
      'Do you know a colleague who uses interpretation services in their profession? Invite her to Túlka',
    invite: 'Invite',
    invite_success: 'Invite successfully sent.',
    invite_to_group: 'Invite new people',
    collaborators_header: 'Your collaborators',
    add_collaborators: 'Add collaborators',
    please_enter_email: 'Please enter email',
    name: 'Name',
    collaborators_header_description:
      'Invite a collaborator to co-manage your bookings. \nNote: You can invite an existing user only.',
    user_does_not_exist:
      'User does not exist, you can invite only existing user',
    invite_to_collaborate_success: 'User successfully invited to collaborate.',
    collaborator_successfully_removed: 'Collaborator successfully removed.',
    invalid_email_address: 'Invalid email address',
    invalid_phone_number: 'Invalid phone number',
    invalid_phone_number_error: 'Invalid phone number',
    already_registered_phone: 'This phone number is already registered to another user. Please contact customer service ',
    phone_number_cannot_start_with_0: 'Phone number cannot start with 0 in international format',
    email_address_from_different_organisation_domain:
      'Email address from different domain',
    this_user_already_added_to_collaborators:
      'This user already added to collaborators',
    prebooked: 'Prebooked',
    ondemand: 'On demand',
    reset_user_password: 'Change password',
    reset_user_email: 'Change email for booking confirmations',
    reset_user_email_for_login: 'Change email for login',
    please_enter_password: 'Password',
    please_enter_new_password: 'New password',
    please_enter_current_password: 'Current password',
    please_repeat_password: 'Repeat password',
    please_repeat_new_password: 'New password again',
    passwords_do_not_match: 'Passwords do not match',
    old_password_is_required: 'Old password is required',
    enter_new_password: 'Enter new password',
    repeat_new_password: 'Repeat new password',
    password_requirement:
      'Password must be aleast 8 characters, including an uppercase and lowercase letter, a number',
    all_day: 'All day',
    previous: 'Previous',
    next: 'Next',
    // today: 'Today',
    month: 'Month',
    week: 'Week',
    day: 'Day',
    agenda: 'Agenda',
    date: 'Date',
    // time: 'Time',
    event: 'Event',
    no_events_in_range: 'No events in range',
    show_more: 'Show more',
    reports: 'Reports',
    number_of_interpretations: 'Tulkkausmäärä',
    interpretation_breakdown_previous_month:
      'Tulkkauserittely - edellinen kuukausi',
    your_browser_is_not_supported: 'Your Browser is not Supported.',
    edge_browser_is_not_supported: 'EDGE browser is not supported. Please use Google Chrome.',
    we_recommend_using_the_latest_version_of_chrome_or_firefox_in_order_to_continue_using_this_service:
      'We recommend using the latest version of Chrome or Firefox in order to continue using this service.',
    use_of_interpretation_report: 'Tulkkauksen Käyttö',
    billing_report: 'Laskutus',
    customer_reporting_report: 'Asiakasraportointi',
    data_export_report: 'Tiedon Vienti',
    monetra_billing_report: 'Monetra Laskutus',
    monetra_payroll_report: 'Monetra Palkanlasku',
    copy_data_to_new_booking: 'Copy data to new booking',
    replace_with_new_one: 'Replace with new one',
    update_at_least_date_or_time_fields_to_create_a_booking:
      'Update at least Date or Time fields to create a booking',
    cancel_prebook: 'Cancel',
    newButton: 'New',
    announcement_interpretation: 'Announcement interpretation',
    customer_name_and_date_of_birth:
      'Customer name and date of birth (for invoice)',
    customer_name: 'Customer name (for invoice)',
    date_of_birth: 'Date of birth (for invoice)',
    to_whom_the_message_is_forwarded_if_not_to_the_customer:
      'To whom the message is forwarded, if not to the customer',
    telephone_number_to_which_the_interpreter_will_call:
      'Telephone number (s) to which the interpreter will call',
    main_telephone_number_to_which_the_interpreter_will_call:
      'Main telephone number to which the interpreter will call',
    additional_telephone_number_to_which_the_interpreter_will_call:
      'Additional telephone number(s) to which the interpreter will call',
    message_to_customer_service_or_interpreter:
      'Message to customer service or interpreter',
    mandatory_information: 'Mandatory information',
    optional_information: 'Optional information',
    customer_data: 'Customer data',
    write_the_content: 'Message content',
    write_the_content_of_the_matter_to_be_reported_below:
      'Write the content of the matter to be reported below',
    content_data_tooltip:
      'Message interpretation is a one-way, spoken interpretation of a message. The maximum length of the message is 1000 characters.',
    content_data_second_tooltip:
      'The interpreter will call the given phone number at least 5 times. The interpretation of the message will be considered completed even when the message does not reach the customer.',
    calendar_tooltip_notice_interpretation:
      'The interpreter will call the number provided by the ned of the selected day.',
    calendars: 'Calendars',
    please_select_language: 'Choose language',
    please_select_interpreter: 'Please select interpreter',
    please_select_language_and_interpreter_to_see_the_calendar:
      'Please select language and interpreter to see the calendar',
    please_select_time: 'Please select time',
    block_time: 'Blocked time',
    block_time_description: 'Blocked time - During this time the interpreter will not receive any calls or prebookings.',
    reservations_od: 'Reservation for OD',
    reservations_od_description: 'Reservation for OD - During this time interpreter will not receive prebookings, but will be targeted only to OD',
    unavailable_time: 'Unavailable time',
    unavailability_type: 'Unavailability type',
    pick_start_time: 'Pick a start time',
    pick_end_time: 'Pick an end time',
    time_successfuly_blocked: 'Time successfully blocked',
    time_successfuly_unblocked: 'Blocked time successfully canceled',
    time_successfuly_updated: 'Blocked time successfully updated',
    overlaping_information_dialog_mesage:
      'The dates are overlapping with an existing prebooking or another unavailability. Please remove overlapping unavailabilities, cancel the overlapping prebookings or use different dates.',
    ok: 'ok',
    cancel_blocked_time_confirmation_message:
      'Are you sure that you want to cancel the blocked time?',
    cancel_interpreter_blocked_time: 'Unblock',
    relay_the_message_to_person: 'Relay the message to person:',
    birthday: 'Date of birth:',
    numbers_to_be_called: 'Numbers to be called:',
    message: 'Message:',
    has_to_be_a_phone_number: 'Has to be a phone number',
    other_information_relevant_to_the_invoicing_such_as_information_about_the_customer:
      'Other information relevant to the invoicing, such as information about the customer',
    this_invoice_needs_additional_information:
      'This invoice needs additional information. Please fill in the required fields to confirm your order',
    cancelled_by: 'Cancelled by',
    cancel_reason: 'Cancel reason',
    join_to_meeting: 'Join to meeting',
    ready_to_join: 'Ready to join?',
    what_is_your_name: 'What\'s your name?',
    your_name: 'Your name',
    waiting_for_luona_worker_and_interpreter:
      'Waiting for Luona worker and interpreter...',
    waiting_label: 'Waiting...',
    type_message: 'Type a message',
    looking_for: 'We are trying to provide the best service but sometimes it takes time. Please hold on, we are looking for',
    luona_worker_persone: 'Luona worker',
    interpreter_persone: 'Interpreter',
    interpreter_status: 'On-demand interpretations',
    availability_yes: 'Available now',
    availability_no: 'You are not available.',
    // prebooking luona
    prebooking_page_title: 'Prebooking',
    defaultPrebookingPageMessage: 'You don\'t have any prebookings',
    go_to_prebookings_list: 'Go to prebookings list',
    luona_feedback_title: 'Quality survey',
    luona_feedback_description: 'You have taken advantage of Luona\'s new technological remote service platform. We collect feedback about the service, and we would be grateful if you answer our short survey.',
    luona_feedback_score: 'How likely is it that you would recommend this remote service platform to a friend?',
    luona_feedback: 'Feedback',
    feedback_sent: 'Thank you for your feedback!',
    luona_prefer: 'Preferential',
    Luona_lead: 'Lead team',
    luona_social: 'Social',
    luona_nurses: 'Medical',
    go_to_services: 'Go to services',
    start_prebook: 'Start',
    prebooking_is_gone: 'Prebooking is gone',
    prebooking_not_answered: 'Unfortunately we cannot deliver the prebooking. Book a consultation with a specialist again',
    prebooking_is_not_found: 'Unfortunately, we cannot find a matching active prebooking. Please try to make an emergency call or book a consultation with a specialist again.',
    prebooking_is_cancelled: 'Unfortunately, we cannot find a matching active prebooking. Maybe It was cancelled. Please try to make an emergency call or book a consultation with a specialist again.',
    prebooking_is_too_late: 'Unfortunately, the prebooking start time has passed. After waiting, the interpreters were redirected to handle urgent calls. Please try to make an emergency call or book a consultation with a specialist again.',
    prebooking_will_start_in: 'The start of your appointment is in:',
    early_preview_intro: 'Dear Luona customer!',
    you_can_close_tab_for_now: 'You can close this tab for now.',
    my_prebooking_list: 'My reservations',
    connecting_to_meeting: 'Connecting to meeting',
    connecting_to_meeting_for_interpreter: 'Please hold on to the line, we are connecting all the parties',
    no_access_to_your_camera_or_microphone: 'No access to your camera or microphone',
    remote_backup_to_onsite: 'What to do if no interpreter is found on-site?',
    use_remote_interpretation_option: 'Use remote interpretation',
    cancel_prebooking_option: 'Cancel prebooking',
    which_remote_interpretation_type_do_you_choose: 'Which remote interpretation type do you choose?',
    phone_interpretation: 'Phone interpretation',
    conference_virtual_room: 'Conference / Virtual room (e.g. Google meet, Teams, Zoom)',
    confirm_you_are_online: 'Did you leave? If you want to keep accepting calls please click the button "Join back".',
    join_back: 'Join back',
    requestErrorMessageReasonPoorInternet: 'Oops! Something went wrong. One of the reasons might be poor internet. Please check your internet settings!',
    networkDisconnectedMessage: 'Your internet connection is not fast enough for video calls. Please check your internet settings!',
    interpreter_name: 'Interpreter',
    meeting_disconnected: 'You have been disconnected',
    meeting_reconnecting: 'You are being reconnected',
    meeting_reconnected: 'You have been reconnected',
    browser_does_not_support_video_effects: 'Browser does not support video effects',
    // Self-selfRegistration
    user_group_not_found: 'We are unable to complete your registration at this time because your organization is missing a required configuration. Please contact our customer service team for assistance in resolving this issue.',
    log_in: 'log in',
    sign_up: 'sign up',
    create_an_account: 'Create an account',
    contact_information: 'Contact information',
    first_name: 'First name',
    last_name: 'Last name',
    is_personal_email: 'This email address is being used to verify your identity. Please specify whether this email address is solely for personal communication or shared with colleagues:', 
    email_for_personal: 'Email is solely for personal communication',
    email_shared_with_colleagues: 'Email is shared with colleagues',
    check_email_again: 'Please enter email address again for sending verification email.',
    is_personal_phone: 'This phone number is used to verify your identity. Please specify whether this phone number is solely for personal communication or shared with colleagues:',
    phone_for_personal: 'Phone number is solely for personal communication',
    phone_shared_with_colleagues: 'Phone number is shared with colleagues',
    check_phone_again_by_sms: 'Please enter phone number again for sending verification sms.',
    check_phone_again_by_call: 'Please enter landline number again for receiving an automated call.',
    already_have_an_account_part1: 'Already have an account? ',
    already_have_an_account_part2: 'Log in ',
    contact_info_email: 'Email',
    contact_info_phone: 'Phone number',
    continue: 'Continue',
    confirm_your_email: 'Confirm your email',
    confirm_your_email_message: 'To complete registration, we have sent a verification email to the registered email. Please verify email by following instructions on the email.',
    confirm_your_phone: 'Confirm your phone',
    confirm_your_mobile_phone_message: 'To complete registration, we have sent a verification SMS to the registered phone number. Please verify the phone number by following instructions on the SMS.',
    confirm_your_phone_message: 'You will receive an automated call for verifying ownership of a landline number. During the call you will hear a code, which you need to type here.',
    confirmation_code: 'Confirmation code',
    didnt_receive_an_email: 'Didn\'t receive an email?',
    didnt_receive_a_call: 'Didn\'t receive a call?',
    didnt_receive_sms: 'Didn\'t receive sms?',
    send_email_again: 'Send email again',
    send_phone_again_for_sms: 'Send SMS again',
    send_phone_again_for_call: 'Request another callback',
    verification_page: 'Verification page',
    verification_link_expired: 'Something went wrong. Verification link might have expired. Please try again.',
    waiting_for_verification: 'Waiting for verification', 
    select_the_correct_cost_center_regarding_invoicing: 'Select the correct cost center regarding invoicing. If you can\'t find the correct cost center select "I couldn’t find the correct cost center" -option.',
    how_often_do_you_predict_to_utilize_interpretation_services: 'How often do you predict to utilize interpretation services?',
    daily: 'Daily',
    weekly: 'Weekly',
    more_than_weekly: 'More seldom than weekly',
    periodically: 'Periodically',
    cost_center: 'Cost center',
    i_could_not_find_the_correct_cost_center: 'I couldn\'t find the correct cost center',
    information_regarding_cost_center: 'Information regarding cost center (Industry / department where you work)',
    please_tell_us_where_you_work_in_which_unit_etc: 'Please tell us where you work, in which unit etc.',
    update_user_home_group_failed: 'Update user home group failed',
    set_up_your_password: 'Set up your password',
    repeat_password: 'Repeat password',
    set_password_failed: 'Set password failed',
    submit: 'submit',
    account_created: 'Account created!',
    you_can_now_login_to_the_tulka_application_using_your_credentials: 'You can now log in to Túlka. The button at the bottom of the page takes you directly to the browser version of Túlka.',
    we_have_also_sent_you_instructions_for_use_by_email: 'We have also sent you the instructions by email.',
    open_mobile_app: 'Open mobile app',
    login_to_web: 'Login to WEB',
    account_found: 'Account found',
    please_log_in_or_request_a_new_password: 'Please log in or request a new password.',
    forgot_password: 'Forgot password',
    no_contract_found: 'No contract found',
    it_seems_like_you_dont_have_an_contract_with_tulka_based_on_your_email_address: 'It seems like you don\'t have an contract with Túlka based on your email address.',
    please_contact_our_sales_to_begin_using_tulka: 'If you want to use Túlka, contact our customer service via the chat at the bottom right of the page, by phone on 030 660 6400 or by email at info@tulka.com',
    email_already_registered: 'Email is already registered with another account.',
    contact_service_with_chat: 'Please contact customer service with chat or by calling number ',
    contact_service_without_chat: 'Please contact customer service by calling number ',
    contact_sales: 'by phone on 030 660 6400 or by email at info@tulka.com',
    invalid_code: 'Invalid code',
    invalid_field_value: 'This field is required and it can not be empty.',
    fistName_requirement: 'First name required',
    lastName_requirement: 'Last name required',
    contact_service_contacts: 'tel. 030 660 6400 | info@tulka.com',
    backup_option: 'Backup option',
    no_backup_option_selected: 'No backup option selected',
  },

  da: {}
};

const getLanguageFromBrowser = () => {
  let langCode = (
    ('languages' in navigator ? navigator.languages[0] : null) ||
    navigator.language
  ).substring(0, 2);
  return langCode;
};

export const fullAvailableLangs = ['en', 'fi', 'da'];
export const tulkaLanguages = ['en', 'fi', 'da'];

export const defaultLang = 'en';

export const getLang = () => {
  let currentLang = localStorage.getItem('lang');
  if (selectableLanguages.includes(currentLang)) {
    return currentLang;
  }
  currentLang = getLanguageFromBrowser();
  if (selectableLanguages.includes(currentLang)) {
    return currentLang;
  }
  localStorage.setItem('lang', defaultLang);
  return defaultLang;
};

const getSelectableLanguages = () => {
  return tulkaLanguages;
};

export const setLanguage = (lang) => {
  if (lang && fullAvailableLangs.some(l => l === lang)) {
    localStorage.setItem('lang', lang);
    return;
  } 
  localStorage.removeItem('lang');
};

export const selectableLanguages = getSelectableLanguages();

export const langCode = getLang();

export const getIsRightToLeft = () => {
  const upToDateLangCode = getLang();
  return upToDateLangCode === 'ar' || upToDateLangCode === 'ku';
};

export const setLangOnLoginPage = () => {
  const browserLang = getLanguageFromBrowser();
  if (tulkaLanguages.includes(browserLang)){
    setLanguage(browserLang);
    return;
  }
  setLanguage(defaultLang);
};

const localize = (key) => {
  const upToDateLangCode = getLang();

  const upToDateLocalizations = translations[upToDateLangCode === 'ua' ? 'uk' : upToDateLangCode];
  return upToDateLocalizations[key] || `${key}`;
};

export default localize;
