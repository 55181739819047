const feedbackImprovements = {
  en: [
    {
      key: 'feedback:improvement:1:tech:bad_connection',
      value: 'Bad connection'
    },
    {
      key: 'feedback:improvement:2:tech:connection_dropped',
      value: 'Connection dropped'
    },
    {
      key: 'feedback:improvement:3:tech:app_was_slow',
      value: 'App was slow'
    },
    {
      key: 'feedback:improvement:4:interpretation:interpreter_does_not_introduce',
      value: 'Interpreter doesn’t introduce themselves'
    }, 
    {
      key: 'feedback:improvement:5:interpretation:interpreter_was_in_poor_location',
      value: 'Interpreter in poor location'
    },
    {
      key: 'feedback:improvement:6:interpretation:interpreter_does_not_tell_limited_time',
      value: 'Interpreter doesn’t mention time limitations'
    }
  ],
  fi: [
    {
      key: 'feedback:improvement:1:tech:bad_connection',
      value: 'Huono yhteys'
    },
    {
      key: 'feedback:improvement:2:tech:connection_dropped',
      value: 'Yhteys katkesi'
    },
    {
      key: 'feedback:improvement:3:tech:app_was_slow',
      value: 'Sovellus hidasteli'
    },
    {
      key: 'feedback:improvement:4:interpretation:interpreter_does_not_introduce',
      value: 'Tulkki ei esittäydy'
    },
    {
      key: 'feedback:improvement:5:interpretation:interpreter_was_in_poor_location',
      value: 'Tulkki huonossa paikassa'
    },
    {
      key: 'feedback:improvement:6:interpretation:interpreter_does_not_tell_limited_time',
      value: 'Tulkki ei ilmoittanut ajan rajallisuudesta'
    }
  ],
  da: [
    {
      key: 'feedback:improvement:1:tech:bad_connection',
      value: 'Dårlig forbindelse'
    },
    {
      key: 'feedback:improvement:2:tech:connection_dropped',
      value: 'Forbindelsen blev afbrudt'
    },
    {
      key: 'feedback:improvement:3:tech:app_was_slow',
      value: 'Appen var langsom'
    },
    {
      key: 'feedback:improvement:4:interpretation:interpreter_does_not_introduce',
      value: 'Tolk præsentere sig ikke'
    },
    {
      key: 'feedback:improvement:5:interpretation:interpreter_was_in_poor_location',
      value: 'Tolk i dårlig placering'
    },
    {
      key: 'feedback:improvement:6:interpretation:interpreter_does_not_tell_limited_time',
      value: 'Tolk nævner ikke tidsbegrænsninger'
    }
  ]
};

const feedbackImprovementsAi = {
  en: [
    {
      key: 'feedback:improvement:ai:1:tech:accuracy',
      value: 'Accuracy of interpretation'
    },
    {
      key: 'feedback:improvement:ai:2:tech:context',
      value: 'Context understanding'
    },
    {
      key: 'feedback:improvement:ai:3:tech:terminology',
      value: 'Terminology consistency and understanding'
    },
    {
      key: 'feedback:improvement:ai:4:interpretation:ui_clarity',
      value: 'User interface clarity'
    }, 
    {
      key: 'feedback:improvement:ai:5:interpretation:response_time',
      value: 'Response time'
    },
    {
      key: 'feedback:improvement:ai:6:interpretation:logic',
      value: 'Logic and intuitiveness of functions'
    }
  ],
  fi: [
    {
      key: 'feedback:improvement:ai:1:tech:accuracy',
      value: 'Tarkkuus ja oikeellisuus'
    },
    {
      key: 'feedback:improvement:ai:2:tech:context',
      value: 'Kontekstin ymmärrys'
    },
    {
      key: 'feedback:improvement:ai:3:tech:terminology',
      value: 'Johdonmukaisuus ja termien yhtenäisyys'
    },
    {
      key: 'feedback:improvement:ai:4:interpretation:ui_clarity',
      value: 'Käyttöliittymän selkeys'
    },
    {
      key: 'feedback:improvement:ai:5:interpretation:response_time',
      value: 'Vastausaika'
    },
    {
      key: 'feedback:improvement:ai:6:interpretation:logic',
      value: 'Käytön logiikka ja intuitiivisuus'
    }
  ],
  da: [
    {
      key: 'feedback:improvement:ai:1:tech:accuracy',
      value: 'Nøjagtighed af tolkningen'
    },
    {
      key: 'feedback:improvement:ai:2:tech:context',
      value: 'Forståelse af kontekst'
    },
    {
      key: 'feedback:improvement:ai:3:tech:terminology',
      value: 'Terminologiens konsistens og forståelse'
    },
    {
      key: 'feedback:improvement:ai:4:interpretation:ui_clarity',
      value: 'Klarhed i brugergrænsefladen'
    },
    {
      key: 'feedback:improvement:ai:5:interpretation:response_time',
      value: 'Svar tid'
    },
    {
      key: 'feedback:improvement:ai:6:interpretation:logic',
      value: 'Logik og intuitivitet i funktioner'
    }
  ]
};

export const getFeedbackImprovements = (isAi, lang) => {
  if (isAi) {
    return feedbackImprovementsAi[lang] || [];
  }
  return feedbackImprovements[lang] || [];
};